import background01 from '../assets/images/transactionalModelChanges/certified-suppliers.png'
import background02 from '../assets/images/transactionalModelChanges/receive-and-compare-price-quotations-instantly.png'
import background03 from '../assets/images/transactionalModelChanges/order-tracking-and-management.png'
import background04 from '../assets/images/transactionalModelChanges/secure-transactions.png'

export const transactionalModelChangesContent = {
  subhead: 'BEST-IN-CLASS SOURCING JOURNEY',
  title: 'Efficient sourcing, streamlined orders',
  description: 'The synergy of technology and sustainability maximizes your speed and sales potential.',
  slider: {
    className: 'swiper-cols-4',
    pagination: { clickable: true },
    slidesPerView: 'auto',
    spaceBetween: 24,
    loop: true,
    simulateTouch: true,
    slides: [
      {
        size: 'regular',
        background: background01,
        link: `${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`,
        title: 'Collaborate directly with certified suppliers',
        description:
          'Boost your supply chain with 25,000+ verified suppliers for apparel, fabric, trims, yarns, and home textiles. Save time and resources by streamlining your search for certified companies, simplifying supplier network building.',
      },
      {
        size: 'regular',
        background: background02,
        link: `${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`,
        title: 'Receive and compare price quotations instantly',
        description:
          'Create requests for quotations, compare proposals, and find the ideal partner for your project. Explore costs with alternative suppliers or in different countries, saving time and resources on research and negotiations.',
      },
      {
        size: 'regular',
        background: background03,
        link: `${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`,
        title: 'Place, manage and track orders with confidence',
        description:
          'Ensure on-time delivery and desired product quality with our unique order process. All product and order specifications are managed in one location, and our digital handshake provides legal security.',
      },
      {
        size: 'regular',
        background: background04,
        link: `${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`,
        title: 'Unmatched security in every payment',
        description:
          'Choose our unmatched payment management with integrated escrow and e-wallets for a secure and transparent process. Enjoy peace of mind with pre-agreed terms and final payout upon successful delivery.',
      }
    ],
  },
}
