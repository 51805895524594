// Base
import * as React from 'react'

// Content
import { suppliersContent } from '../../content/suppliers'

// Components
import { Container, Typography } from '@mui/material'
import CardBackground from '../CardBackground'
import CardImage from '../CardImage'

// Styles
import * as S from './Suppliers.styles'

const Suppliers = ({ content }) => {
  const props = { ...suppliersContent, ...content }
  return (
    <S.Suppliers>
      <Container maxWidth='xl' disableGutters sx={{ textAlign: 'center' }}>
        {props?.subhead && <Typography variant='subhead'>{props.subhead}</Typography>}
        {props?.title && (
          <Typography variant='h2' sx={{ color: 'bluishGrey.ten', mb: 5 }}>
            {props.title}
          </Typography>
        )}
        <S.Grid>
          {props?.cards && props.cards.map((card, i) => <CardBackground key={i} content={card} />)}
          {props?.cardMoreSuppliers && <CardImage content={props?.cardMoreSuppliers} />}
        </S.Grid>
      </Container>
    </S.Suppliers>
  )
}
export default Suppliers
