import * as React from 'react'

import { useTranslation } from 'gatsby-plugin-react-i18next'
import Parser from 'html-react-parser'

// Components
import { Box, Container, Typography } from '@mui/material'
import SmallCardBg from '../SmallCardBg'
import SuppliersCard from '../SuppliersCard'
import Slider from '../Slider'
import ShowroomsCard from '../ShowroomsCard'

// Content
import { showroomsContent } from '../../content/showrooms'

const ShowroomsSection = () => {
  const { t } = useTranslation()
  return (
    <Box sx={{ my: 8 }}>
      <Container maxWidth='xl' disableGutters sx={{ textAlign: 'center' }}>
        <Typography variant='subhead'>{Parser(t('DISCOVER PRODUCTS'))}</Typography>
        <Typography variant='h2' sx={{ color: 'bluishGrey.ten', mb: 3 }}>
          {t('Purchase products from showrooms')}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: { xs: 'center', md: 'start' },
            flexDirection: { xs: 'column', md: 'row' },
            mt: 5,
          }}
        >
          <Slider content={showroomsContent(t).slider}>
            <ShowroomsCard />
          </Slider>
          <Box sx={{ textAlign: 'left', ml: { xs: '0', md: 3 }, mt: { xs: 3, md: 0 } }}>
            <SmallCardBg content={showroomsContent(t).smallCardBg} />
            <SuppliersCard content={showroomsContent(t).suppliersCard} />
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default ShowroomsSection
