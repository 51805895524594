// Base
import * as React from 'react'

// Components
import { Chip, Container, Stack, Typography } from '@mui/material'
import Slider from '../Slider'
import MemberTestimonialCard from '../MemberTestimonialCard'

// Styles
import * as S from './MemberTestimonial.styles'

const MemberTestimonial = ({ content }) => {
  return (
    <S.MemberTestimonial sx={{ my: { xs: 6, md: 8 }, mx: 2 }}>
      <Container maxWidth='xl' sx={{ textAlign: 'center', py: { xs: 6, md: 8 } }}>
        {content?.subtitle && <Typography variant='subhead'>{content.subtitle}</Typography>}
        {content?.title && (
          <Typography variant='h2' sx={{ color: 'bluishGrey.ten', mb: 3 }}>
            {content.title}
          </Typography>
        )}
        {content?.slider && (
          <Stack
            className='custom-swiper-pagination'
            direction='row'
            spacing={1}
            justifyContent='center'
            sx={{ display: { xs: 'block', sm: 'flex' }, mb: 8 }}
          >
            {content.slider.slides?.map((slide, i) => (
              <Chip key={i} clickable variant='outlined' color='secondary' label={slide.pagename} />
            ))}
          </Stack>
        )}
        <Container maxWidth='lg'>
          {content?.slider && (
            <Slider content={content.slider}>
              <MemberTestimonialCard />
            </Slider>
          )}
          {content?.card && <MemberTestimonialCard content={content.card} />}
        </Container>
      </Container>
    </S.MemberTestimonial>
  )
}
export default MemberTestimonial
