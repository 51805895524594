import * as React from 'react'

// Styles
import 'react-slideshow-image/dist/styles.css'
import './smallCardBg.scss'

//Components

const SmallCardBg = ({ content }) => {
  var bgImg = {
    backgroundImage: 'url(' + content.backgroundImg + ')',
  }
  return (
    <a href={content.link} className={'text-decoration-none'}>
      <div className={'smallCardBg d-flex flex-column justify-content-between ' + content.extraClass} style={bgImg}>
        <b className={'font-size-18 ln-21'}>{content.text}</b>
        <div className='d-flex align-items-center'>
          <b className={'font-size-14 me-s-8'}> {content.linkText}</b>
          <img src={content.arrow} height={16} alt={'Arrow Right'} />
        </div>
      </div>
    </a>
  )
}

export default SmallCardBg
