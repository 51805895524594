import styled from 'styled-components'

export const Suppliers = styled.section`
  margin: 64px 0;
`

export const Grid = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 24px;
  & > a {
    max-width: 304px;
    flex-basis: 304px;
  }
`
