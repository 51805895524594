// Base
import * as React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Parser from 'html-react-parser'

// Components
import { Box, Container, SvgIcon, Typography } from '@mui/material'
import CtaButton from '../CtaButton'

// Assets
import QuoteIcon from '../../assets/images/svg/quote.inline.svg'

const MemberTestimonialCard = ({ content }) => {
  return (
    <Box sx={{ px: { xs: '48px', md: '24px' } }}>
      <Container
        maxWidth='md'
        sx={{
          textAlign: 'center',
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <Box sx={{ mb: { xs: 4, md: 0 } }}>
          {content?.avatar?.src &&
            (typeof content.avatar.src === 'object' ? (
              <GatsbyImage image={getImage(content.avatar.src)} alt={content.avatar.alt} />
            ) : (
              <img src={content.avatar.src} alt={content.avatar.alt} height={240} width={240} />
            ))}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            textAlign: { md: 'left' },
            alignItems: { xs: 'center', md: 'flex-start' },
            ml: { xs: 0, md: 4 },
          }}
        >
          {content?.description && (
            <SvgIcon component={QuoteIcon} inheritViewBox sx={{ fontSize: 24, mr: { md: 2 }, mb: { xs: 4, md: 0 } }} />
          )}
          <Box>
            <Typography sx={{ mb: 2 }}> {Parser(content.description)}</Typography>
            {content?.name && (
              <Typography variant='body1Bold' component='p' sx={{ color: 'bluishGrey.eight', mb: 2 }}>
                {content.name}
              </Typography>
            )}
            <Typography variant='subhead'>
              {content.position}
              <br />
              {content.company}
            </Typography>

            {content?.button && <CtaButton content={content?.button} />}
          </Box>
        </Box>
      </Container>
    </Box>
  )
}
export default MemberTestimonialCard
