// Assets
import avatarApparelSupplier from '../assets/images/testimonial/apparel-supplier-quote.png'
import avatarFabricSupplier from '../assets/images/testimonial/fabric-supplier-quote.png'

const customPagination = {
  clickable: true,
  el: '.custom-swiper-pagination',
  renderBullet: function (index, className) {
    return (
      '<span class="' +
      className +
      ' custom-' +
      className +
      '">' +
      memberTestimonialContent.slider.slides[index].pagename +
      '</span>'
    )
  },
}

export const memberTestimonialContent = {
  title: 'Built For All Industry Players',
  subtitle: 'What our members say',
  slider: {
    className: 'swiper-no-padding',
    loop: true,
    navigation: true,
    effect: 'fade',
    pagination: customPagination,
    simulateTouch: false,
    slides: [
      {
        avatar: {
          src: avatarApparelSupplier,
          alt: 'Sílvia Costa',
        },
        description:
          "When needing a new supplier, an article, or a product with some certification, the buyer first explores the digital channels. First, they decide on the country they want to source from and then look for what they want. It's the fastest, most comprehensive, and most inexpensive way to do the first search for a product solution. Foursource is a network where I can connect with these buyers, easily and for a fair price!",
        name: 'Sílvia Costa',
        position: 'General Manager',
        company: 'BLESS Internacional',
        pagename: 'For Apparel Suppliers',
        button: {
          text: 'Join for free',
          href: 'SIGNUP',
          size: 'small',
          variant: 'contained',
        },
      },
      {
        avatar: {
          src: avatarFabricSupplier,
          alt: 'Fatih Gökçen',
        },
        description:
          'Foursource has enabled me to use time effectively. We were able to increase the number of potential buyers I could reach, and we can find potential new customers every day.',
        name: 'Fatih Gökçen',
        position: 'Marketing Manager',
        company: 'Bade Fabrics Textile',
        pagename: 'For Fabric Suppliers',
        button: {
          text: 'Join for free',
          href: 'SIGNUP',
          size: 'small',
          variant: 'contained',
        },
      },
    ],
  },
}
