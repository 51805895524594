import React from 'react'

// Images
import ApparelIcon from '../assets/images/svg/apparel.inline.svg'
import FabricsIcon from '../assets/images/svg/fabric.inline.svg'
import YarnsIcon from '../assets/images/svg/yarns.inline.svg'
import TrimsIcon from '../assets/images/svg/trims.inline.svg'
import HomeTextilesIcon from '../assets/images/svg/home-textiles.inline.svg'
import ComplianceIcon from '../assets/images/svg/compliance.inline.svg'
import RightIcon from '../assets/images/svg/right.inline.svg'

import ApparelBackground from '../assets/images/suppliers/popular-categories-apparel.png'
import FabricsBackground from '../assets/images/suppliers/popular-categories-fabric.png'
import YarnsBackground from '../assets/images/suppliers/popular-categories-yarns.png'
import TrimsBackground from '../assets/images/suppliers/popular-categories-trims.png'
import HomeTextilesBackground from '../assets/images/suppliers/popular-categories-home-textiles.png'
import MoreSuppliersImage from '../assets/images/connect/connect-in-the-industry.svg'

export const suppliersContent = {
  subhead: 'Connect With',
  title: 'Suppliers in the Network',
  cards: [
    {
      variant: 'supplier',
      icon: ApparelIcon,
      subhead: 'Apparel',
      title: 'Discover products and companies in the <strong>apparel network.</strong>',
      background: ApparelBackground,
      href: '/explore-network/?type=apparel',
      buttons: {
        color: '#226E50',
        bgcolor: '#FFFFFF',
        items: ['T-Shirts & Polos', 'Sweatshirts', 'Dresses & Skirts', 'Sportswear', 'Kids'],
      },
    },
    {
      variant: 'supplier',
      icon: FabricsIcon,
      subhead: 'Fabrics',
      title: 'Discover products and companies in the <strong>fabrics network.</strong>',
      background: FabricsBackground,
      href: '/explore-network/?type=fabrics',
      buttons: {
        color: '#062A85',
        bgcolor: '#FFFFFF',
        items: ['Woven', 'Non-woven', 'Knit (flat)', 'Cotton', 'Linen & Hemp', 'Silk', 'Crochet/Lace'],
      },
    },
    {
      variant: 'supplier',
      icon: TrimsIcon,
      subhead: 'Trims',
      title: 'Discover products and companies in the <strong>trims network.</strong>',
      background: TrimsBackground,
      href: '/explore-network/?type=trims',
      buttons: {
        color: '#1D2947',
        bgcolor: '#FFFFFF',
        items: ['Closures', 'Packaging', 'Tapes & Accessories', 'Labels', 'Plastic 3D Printing', 'Cotton'],
      },
    },
    {
      variant: 'supplier',
      icon: YarnsIcon,
      subhead: 'Yarns',
      title: 'Discover products and companies in the <strong>yarns network.</strong>',
      background: YarnsBackground,
      href: '/explore-network/?type=yarns',
      buttons: {
        color: '#008092',
        bgcolor: '#FFFFFF',
        items: ['Natural', 'Knitting Yarns', 'Polyester (Recycled)', 'Lyocell', 'Techtex & Innovative'],
      },
    },
    {
      variant: 'supplier',
      tag: {
        text: 'New',
        bgcolor: '#226E50',
        color: '#FFFFFF',
      },
      icon: HomeTextilesIcon,
      subhead: 'Home Textiles',
      title: 'Discover products and companies in the <strong>Home Textiles network.</strong>',
      background: HomeTextilesBackground,
      href: '/explore-network/?type=hometextiles',
      buttons: {
        color: '#7D90D2',
        bgcolor: '#FFFFFF',
        items: [
          'Home Decoration',
          'Rugs',
          'Pets',
          'Curtains',
          'Bathroom Textitles',
          'Bedding',
          'Kitchen Linens & Textiles',
        ],
      },
    },
    {
      variant: 'supplier',
      subhead: 'Compliance Tracker',
      title: 'Find companies who match your <strong>compliance standards.</strong>',
      color: '#226E50',
      backgroundColor: '#CBF5CF',
      href: '/explore-network',
      buttons: {
        icon: <ComplianceIcon />,
        color: '#226E50',
        bgcolor: '#EFFFF1',
        items: ['GOTS', 'GRS', 'OEKO-TEX', 'OCS', 'ISO-9000', 'BLUEDESIGN'],
      },
    },
  ],
  cardMoreSuppliers: {
    image: MoreSuppliersImage,
    title: 'Discover more suppliers',
    button: {
      text: 'Explore network',
      href: '/explore-network',
      variant: '',
      size: 'small',
      color: 'primary',
      endIcon: <RightIcon />,
    },
  },
}
