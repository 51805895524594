// Base
import * as React from 'react'

// Components
import { Container, Typography } from '@mui/material'
import Slider from '../Slider'
import CardBackground from '../CardBackground'

// Styles
import * as S from './TransactionalModelChanges.styles'

const TransactionalModelChanges = ({ content }) => {
  return (
    <S.TransactionalModelChanges>
      <Container maxWidth='xl' disableGutters sx={{ textAlign: 'center' }}>
        {content?.subhead && <Typography variant='subhead'>{content.subhead}</Typography>}
        {content?.title && (
          <Typography variant='h2' sx={{ color: 'bluishGrey.ten', mb: 3 }}>
            {content.title}
          </Typography>
        )}
        {content?.description && (
        <Typography
          variant='body1'
          sx={{ color: 'bluishGrey.ten', textShadow: (theme) => theme.textShadows[3], mb: 4 }}>
          {content.description}
        </Typography>
        )}
        {content?.slider && (
          <Slider content={content.slider}>
            <CardBackground />
          </Slider>
        )}
      </Container>
    </S.TransactionalModelChanges>
  )
}
export default TransactionalModelChanges
