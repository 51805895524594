// Base
import * as React from 'react'

// Components
import { Card, CardContent, CardMedia, Typography } from '@mui/material'
import CtaButton from '../CtaButton'

const CardImage = ({ content }) => {
  return (
    <Card sx={{ textAlign: 'left', maxWidth: 304, backgroundColor: '#FFFFFF', border: '1px solid #E6E8EF' }}>
      {content?.image && (
        <CardMedia
          component='img'
          height='86'
          image={content?.image}
          alt={content?.title}
          sx={{ borderBottom: '1px solid #E6E8Ed' }}
        />
      )}
      <CardContent sx={{ '&&': { p: 2 } }}>
        {content?.title && (
          <Typography variant='body1Bold' component='h5' noWrap color='bluishGrey.ten' sx={{ mb: 1 }}>
            {content.title}
          </Typography>
        )}
        {content?.button && <CtaButton content={content.button} />}
      </CardContent>
    </Card>
  )
}

export default CardImage
