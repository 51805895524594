import styled from 'styled-components'
import { Box } from '@mui/material'

import MemberTestimonialBackground from '../../assets/images/svg/what-members-say-background.svg'

export const MemberTestimonial = styled(Box)`
  display: flex;
  align-items: center;
  & > .MuiContainer-root {
    background: #f9fafc;
    background-image: url(${MemberTestimonialBackground});
    border-radius: 24px;
    background-size: cover;
  }
`
