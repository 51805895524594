// Base
import * as React from 'react'
import Parser from 'html-react-parser'

// Components
import { Box, Card, Chip, SvgIcon, Typography } from '@mui/material'

const CardBackground = ({ content }) => {
  if (content?.variant === 'supplier') {
    return (
      <Card
        component='a'
        href={content?.href}
        sx={{
          position: 'relative',
          display: 'block',
          width: '100%',
          height: content?.size === 'regular' ? 376 : content?.size === 'full' ? 376 : 176,
          backgroundImage: `url('${content?.background}')`,
          backgroundColor: content?.backgroundColor,
          backgroundSize: 'cover',
          borderRadius: 1.5,
          '&:hover': {
            '& .MuiBox-root': {
              opacity: 1,
              transform: 'translateY(0)',
            },
            '& .MuiTypography-body1': {
              opacity: 0,
              transform: 'translateY(-65px)',
            },
          },
        }}
      >
        <Box
          sx={{
            height: '100%',
            p: 2,
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            justifyContent: 'space-between',
            color: content.color || 'bluishGrey.one',
            textAlign: 'left',
            alignItems: 'flex-start',
          }}
        >
          {content?.tag && (
            <Chip
              size='small'
              label={content.tag.text}
              sx={{
                position: 'absolute',
                right: 16,
                top: 12,
                fontSize: 12,
                backgroundColor: content.tag.bgcolor,
                color: content.tag.color,
              }}
            />
          )}

          {content?.subhead && (
            <Typography
              component='div'
              variant='subhead'
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: content.color || 'bluishGrey.one',
                mb: 0,
              }}
            >
              {content.icon && <SvgIcon component={content.icon} inheritViewBox sx={{ fontSize: 16, mr: 0.5 }} />}
              {content.subhead}
            </Typography>
          )}
          {content?.title && (
            <Typography
              component='h5'
              variant='body1'
              sx={{ transition: 'all 300ms cubic-bezier(0.4,0,0.2,1)', mb: 1 }}
            >
              {Parser(content.title)}
            </Typography>
          )}
          {content?.buttons && (
            <Box
              sx={{
                display: 'flex',
                flexWrap: 'wrap',
                position: 'absolute',
                bottom: '16px',
                opacity: 0,
                transform: 'translateY(110%)',
                transition: 'transform 350ms cubic-bezier(0.4,0,0.2,1), opacity 450ms cubic-bezier(0.4,0,0.2,1)',
              }}
            >
              {content.buttons.items.map((item, i) => (
                <Chip
                  key={i}
                  icon={content.buttons.icon}
                  label={item}
                  sx={{
                    mr: 1,
                    mt: 1,
                    color: content.buttons.color,
                    backgroundColor: content.buttons.bgcolor,
                    cursor: 'pointer',
                  }}
                />
              ))}
            </Box>
          )}
        </Box>
      </Card>
    )
  } else {
    return (
      <Card
        component='a'
        href={content?.link}
        sx={{
          position: 'relative',
          display: 'block',
          height: content?.size === 'regular' ? 376 : content?.size === 'full' ? 376 : 176,
          backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 58.33%, rgba(0, 0, 0, 0.4) 100%), url('${content?.background}')`,
          backgroundSize: 'cover',
          '&:hover': {
            '& .overlay': {
              opacity: 1,
            },
            '& .MuiTypography-subhead': {
              opacity: content?.size === 'mini' && 0,
              height: content?.size === 'regular' ? 17 : 0,
              transform: content?.size === 'mini' && 'translateY(-17px)',
            },
            '& .MuiTypography-body3': {
              opacity: 1,
              height: content?.size === 'regular' ? 102 : content?.size === 'full' ? 110 : 68,
              transform: 'translateY(0)',
            },
            '& .MuiTypography-buttonSmall': {
              opacity: 0,
              height: 0,
              transform: 'translateY(-17px)',
            },
          },
        }}
      >
        <Box
          className='overlay'
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0) 58.33%, rgba(0, 0, 0, 0.4) 100%), url('${content?.background}')`,
            backgroundSize: 'cover',
            opacity: 0,
            transition: 'all 250ms cubic-bezier(0.4,0,0.2,1)',
            filter: 'grayscale(1) brightness(0.5) blur(1px)',
          }}
        ></Box>
        <Box
          sx={{
            height: '100%',
            p: 2,
            display: 'flex',
            position: 'relative',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            color: 'bluishGrey.one',
            textAlign: 'left',
            alignItems: 'flex-start',
          }}
        >
          <Box>
            {content?.subhead && (
              <Typography
                component='div'
                variant='subhead'
                sx={{
                  height: 17,
                  transition: 'all 250ms cubic-bezier(0.4,0,0.2,1)',
                }}
              >
                {content.subhead}
              </Typography>
            )}
            {content?.title && (
              <Typography
                component='h5'
                variant='body1Bold'
                my={1}
                sx={{
                  transition: 'all 250ms cubic-bezier(0.4,0,0.2,1)',
                }}
              >
                {content.title}
              </Typography>
            )}
            {!content.readMore && (
              <Typography
                component='div'
                variant='buttonSmall'
                sx={{
                  height: 17,
                  transition: 'all 250ms cubic-bezier(0.4,0,0.2,1)',
                }}
              >
                Read more
              </Typography>
            )}
            {content?.description && (
              <Typography
                component='p'
                variant='body3'
                sx={{
                  opacity: 0,
                  height: 0,
                  transform: 'translateY(100%)',
                  transition: 'all 300ms cubic-bezier(0.4,0,0.2,1)',
                }}
              >
                {Parser(content.description)}
              </Typography>
            )}
          </Box>
        </Box>
      </Card>
    )
  }
}
export default CardBackground
