// Images
// import logo00 from '../assets/images/logos/default-company-logo.png'
import logo01 from '../assets/images/logos/logo-yunhuiGarment.jpg'
import logo02 from '../assets/images/logos/logo-winprodTexagility.jpg'
import logo03 from '../assets/images/logos/logo-xiamenWellGoldIndustry.jpg'
import logo04 from '../assets/images/logos/logo-maglificio.jpg'
import logo05 from '../assets/images/logos/logo-clothly.jpg'
import logo06 from '../assets/images/logos/logo-chanexGoup.jpg'
import logo07 from '../assets/images/logos/logo-jiaxingYushingImport.jpg'
import logo08 from '../assets/images/logos/logo-katifApparel.jpg'
import logo09 from '../assets/images/logos/logo-modelitTekstil.jpg'
// import logo10 from '../assets/images/logos/youngone-logo.png'
// import logo11 from '../assets/images/logos/nc-isa-industry-and-trade-company-logo.jpeg'
// import logo12 from '../assets/images/logos/huafeng-logo.jpeg'

import productImg01 from '../assets/images/showrooms/yunhuiGarment-product-img.png'
import productImg02 from '../assets/images/showrooms/winprodTexagility-product-img.png'
import productImg03 from '../assets/images/showrooms/xiamenWellGoldIndustry-product-img.png'
import productImg04 from '../assets/images/showrooms/maglificio-product-img.png'
import productImg05 from '../assets/images/showrooms/clothly-product-img.png'
import productImg06 from '../assets/images/showrooms/chanexGroup-product-img.png'
import productImg07 from '../assets/images/showrooms/jiaxingYushingImport-product-img.png'
import productImg08 from '../assets/images/showrooms/katifApparel-product-img.png'
import productImg09 from '../assets/images/showrooms/modelitTekstil-product-img.png'
// import productImg10 from '../assets/images/showrooms/nc-isa-industry-and-trade-company-product-img.jpg'
// import productImg11 from '../assets/images/showrooms/huafeng-product-img.jpg'
// import productImg12 from '../assets/images/showrooms/ykk-europe-limited.jpg'
// import productImg13 from '../assets/images/showrooms/youngone-corporation.jpg'
// import productImg14 from '../assets/images/showrooms/allied-feather-down-product-img.jpg'
// import productImg15 from '../assets/images/showrooms/thermore-far-east-ltd-product-img.jpg'

import buyerCta from '../assets/images/showrooms/buyer-cta.png'
import becomeSupplier from '../assets/images/suppliers/become-supplier.svg'

// Icons
import arrowRightGreen from '../assets/images/icons/arrow-right-green.svg'

export const showroomsContent = (t) => {
  return {
    slider: {
      className: 'swiper-showrooms',
      slidesPerView: 'auto',
      spaceBetween: 24,
      simulateTouch: true,
      pagination: {
        clickable: true,
        dynamicBullets: true,
        renderBullet: function (index, className) {
          return `<span class="${className}"><span class="swiper-pagination-bullet-default"></span></span>`
        },
      },
      loop: true,
      autoplay: {
        pauseOnMouseEnter: true,
        disableOnInteraction: false,
      },
      navigation: true,
      group: 'true',
      slides: [
        [
          {
            companyName: 'Yunhui Garment',
            companyLogo: logo01,
            link: 'https://platform.foursource.com/signup?_ga=2.185188385.948097144.1717146214-1744621028.1713881171&_gac=1.127477119.1715864922.CjwKCAjwl4yyBhAgEiwADSEjeLri3sG-2aulsERu_pjO8aOjfs4ZLRmVg1_ZvhMFn4xRmxyGorHUVBoCRdMQAvD_BwE',
            city: 'China',
            country: 'China',
            img: productImg01,
            productName: 'Suit dress',
            productDescription: '97% Polyester, 3% Spandex',
          },
          {
            companyName: 'Winprod Texagility Lda',
            companyLogo: logo02,
            link: 'https://platform.foursource.com/signup?_ga=2.185188385.948097144.1717146214-1744621028.1713881171&_gac=1.127477119.1715864922.CjwKCAjwl4yyBhAgEiwADSEjeLri3sG-2aulsERu_pjO8aOjfs4ZLRmVg1_ZvhMFn4xRmxyGorHUVBoCRdMQAvD_BwE',
            city: 'Portugal',
            country: 'Portugal',
            img: productImg02,
            productName: 'Classic Woman Socks',
            productDescription: '100% Cotton',
          },
          {
            companyName: 'Xiamen WellGold Industry Co., Ltd.',
            companyLogo: logo03,
            link: 'https://platform.foursource.com/signup?_ga=2.185188385.948097144.1717146214-1744621028.1713881171&_gac=1.127477119.1715864922.CjwKCAjwl4yyBhAgEiwADSEjeLri3sG-2aulsERu_pjO8aOjfs4ZLRmVg1_ZvhMFn4xRmxyGorHUVBoCRdMQAvD_BwE',
            city: 'Zhejiang',
            country: 'China',
            img: productImg03,
            productName: 'Workout Clothing',
            productDescription: '75% Nylon, 25% Spandex',
          },
        ],
        [
          {
            companyName: 'Maglificio G.A.Emme Srl',
            companyLogo: logo04,
            link: 'https://platform.foursource.com/signup?_ga=2.185188385.948097144.1717146214-1744621028.1713881171&_gac=1.127477119.1715864922.CjwKCAjwl4yyBhAgEiwADSEjeLri3sG-2aulsERu_pjO8aOjfs4ZLRmVg1_ZvhMFn4xRmxyGorHUVBoCRdMQAvD_BwE',
            city: 'Italy',
            country: 'Italy',
            img: productImg04,
            productName: "D12N65",
            productDescription: '30% Cashmere, 70% Merino',
          },
          {
            companyName: 'Clothly',
            companyLogo: logo05,
            link: 'https://platform.foursource.com/signup?_ga=2.185188385.948097144.1717146214-1744621028.1713881171&_gac=1.127477119.1715864922.CjwKCAjwl4yyBhAgEiwADSEjeLri3sG-2aulsERu_pjO8aOjfs4ZLRmVg1_ZvhMFn4xRmxyGorHUVBoCRdMQAvD_BwE',
            city: 'Netherlands',
            country: 'Netherlands',
            img: productImg05,
            productName: 'Nylon Track Set',
            productDescription: '12% Acetate',
          },
          {
            companyName: 'Chanex Group',
            companyLogo: logo06,
            link: 'https://platform.foursource.com/signup?_ga=2.185188385.948097144.1717146214-1744621028.1713881171&_gac=1.127477119.1715864922.CjwKCAjwl4yyBhAgEiwADSEjeLri3sG-2aulsERu_pjO8aOjfs4ZLRmVg1_ZvhMFn4xRmxyGorHUVBoCRdMQAvD_BwE',
            city: 'Turkey',
            country: 'Turkey',
            img: productImg06,
            productName: 'Classic Trousers',
            productDescription: '50% Cotton',
          },
        ],
        [
          {
            companyName: 'JIAXING YUSHING IMPORT&EXPORT CO., LTD',
            companyLogo: logo07,
            link: 'https://platform.foursource.com/signup?_ga=2.185188385.948097144.1717146214-1744621028.1713881171&_gac=1.127477119.1715864922.CjwKCAjwl4yyBhAgEiwADSEjeLri3sG-2aulsERu_pjO8aOjfs4ZLRmVg1_ZvhMFn4xRmxyGorHUVBoCRdMQAvD_BwE',
            city: '',
            country: 'China',
            img: productImg07,
            productName: "Define Cropped Jacket",
            productDescription: '80% Nylon, 20% Spandex',
          },
          {
            companyName: 'Katif Apparel',
            companyLogo: logo08,
            link: 'https://platform.foursource.com/signup?_ga=2.185188385.948097144.1717146214-1744621028.1713881171&_gac=1.127477119.1715864922.CjwKCAjwl4yyBhAgEiwADSEjeLri3sG-2aulsERu_pjO8aOjfs4ZLRmVg1_ZvhMFn4xRmxyGorHUVBoCRdMQAvD_BwE',
            city: 'Pakistan',
            country: 'Pakistan',
            img: productImg08,
            productName: 'Men Set',
            productDescription: '100% Cotton',
          },
          {
            companyName: 'Modelit Tekstil',
            companyLogo: logo09,
            link: 'https://platform.foursource.com/signup?_ga=2.185188385.948097144.1717146214-1744621028.1713881171&_gac=1.127477119.1715864922.CjwKCAjwl4yyBhAgEiwADSEjeLri3sG-2aulsERu_pjO8aOjfs4ZLRmVg1_ZvhMFn4xRmxyGorHUVBoCRdMQAvD_BwE',
            city: 'Turkey',
            country: 'Turkey',
            img: productImg09,
            productName: 'Walkiddy',
            productDescription: '100% Cotton',
          },
        ],
        // [
        //   {
        //     companyName: 'NC ISA INDUSTRY AND TRADE COMPANY',
        //     companyLogo: logo11,
        //     link: 'https://foursource.com/explore-network/',
        //     city: 'Nanchang',
        //     country: 'China',
        //     img: productImg10,
        //     productName: 'High Quality And Exquisite Accessories',
        //     productDescription: 'Labels, Waist Tags, 99% Polyester',
        //   },
        //   {
        //     companyName: 'Huafeng',
        //     companyLogo: logo12,
        //     link: 'https://foursource.com/explore-network/',
        //     city: 'Putian',
        //     country: 'China',
        //     img: productImg11,
        //     productName: 'HAPTIC Permeation',
        //     productDescription: 'Tapes & Accessories, 100% Polyester (Recycled), Ink Printing, Plastic 3D Printing',
        //   },
        //   {
        //     companyName: 'YKK Europe Limited',
        //     companyLogo: logo00,
        //     link: 'https://foursource.com/explore-network/',
        //     city: 'Runcorn',
        //     country: 'United Kingdom',
        //     img: productImg12,
        //     productName: 'AquaLock™',
        //     productDescription: 'Closures, Zippers, 100% Polyurethane',
        //   },
        // ],
        // [
        //   {
        //     companyName: 'Youngone Corporation',
        //     companyLogo: logo10,
        //     link: 'https://foursource.com/explore-network/',
        //     city: 'Seoul',
        //     country: 'South Korea',
        //     img: productImg13,
        //     productName: 'EcoLoft™ Zero',
        //     productDescription: 'Synthetic, Cabled Yarn, 100% Polyester',
        //   },
        //   {
        //     companyName: 'ALLIED Feather & Down',
        //     companyLogo: logo00,
        //     link: 'https://foursource.com/explore-network/',
        //     city: 'Montebello',
        //     country: 'United States',
        //     img: productImg14,
        //     productName: 'ExpeDRY ultra dry down',
        //     productDescription: 'Natural, Cabled Yarn, 100% Cashgora',
        //   },
        //   {
        //     companyName: 'Thermore (Far East) Ltd.',
        //     companyLogo: logo00,
        //     link: 'https://foursource.com/explore-network/',
        //     city: 'Hong Kong',
        //     country: 'Hong Kong',
        //     img: productImg15,
        //     productName: 'EVOdown® 80',
        //     productDescription: 'Synthetic, Cabled Yarn, 100% Recycled polyester (rPET)',
        //   },
        // ],
      ],
    },
    smallCardBg: {
      backgroundImg: buyerCta,
      text: t('Find +600,000 products in supplier showrooms'),
      linkText: t('Explore more'),
      link: `${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`,
      extraClass: 'showroomsSectionSmallCardBg',
      arrow: arrowRightGreen,
    },
    suppliersCard: {
      extraClass: 'suppliersCardMd mt-s-24',
      header: becomeSupplier,
      headerHeight: 192,
      btn: true,
      text: t('Want to become a supplier on foursource?'),
      linkText: t('Join as a supplier'),
      link: '/for-suppliers',
    },
  }
}
