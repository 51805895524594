import background01 from '../assets/images/trendsAndEvents/ispo-textrends-01.png'
import background02 from '../assets/images/trendsAndEvents/asm-from-portugal-01.png'
import background03 from '../assets/images/trendsAndEvents/turkey-apparel-textile-hub-01.png'
import background04 from '../assets/images/trendsAndEvents/time-for-china-01.png'
import background05 from '../assets/images/trendsAndEvents/texworld-evolution-paris-01.png'
import background06 from '../assets/images/trendsAndEvents/emerging-pakistan-hub.jpg'

export const tradeshowsAndHubsContent = {
  subhead: 'Tradeshows & Sourcing Hubs',
  title: 'Explore Trends & Events',
  slider: {
    className: 'swiper-cols-4',
    pagination: { clickable: true },
    slidesPerView: 'auto',
    spaceBetween: 24,
    loop: true,
    simulateTouch: true,
    slides: [
      {
        size: 'regular',
        background: background06,
        link: `${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`,
        title: 'Emerging Pakistan Hub',
        description:
          'Discover suppliers from Pakistan inside Foursource’s exclusive and curated sourcing hub, Emerging Pakistan.',
      },
      {
        size: 'regular',
        background: background01,
        link: `${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`,
        title: 'ISPO Textrends Award Hub',
        description:
          'The Textrends Award is a trusted source for research and direction on where the textile and apparel sector is heading. This Hub celebrates innovation, sustainability, and creativity.',
      },
      {
        size: 'regular',
        background: background02,
        link: `${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`,
        title: 'ASM Portugal Hub',
        description:
          'The Portuguese textile sector is a synonym for quality, flexibility and quick response to its clients. Discover apparel and fabric suppliers from Portugal in this exclusive sourcing hub.',
      },
      {
        size: 'regular',
        background: background03,
        link: `${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`,
        title: 'Turkey Hub',
        description:
          'In the Turkish Hub, you can browse profiles of apparel and fabric suppliers and learn more about their current co-operations in Turkey.',
      },
      {
        size: 'regular',
        background: background05,
        link: `${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`,
        title: 'Texworld Evolution Paris',
        description:
          'This event offers professional buyers from all over the world a sourcing platform with a wide variety of products ranging from basics to the most creative and qualitative.',
      },
      {
        size: 'regular',
        background: background04,
        link: `${process.env.GATSBY_CHINA === 'true' ? process.env.APP_CHINA_URL : process.env.APP_URL}/signup`,
        title: 'China Hub',
        description:
          'China is one of the largest sourcing countries for apparel and textiles in the world and is known for fast delivery and high quality products.',
      },
    ],
  },
}
