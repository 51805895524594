import * as React from 'react'

// Styles
import './suppliersCard.scss'

const SuppliersCard = ({ content }) => {
  return (
    <div className={'suppliersCard ' + content.extraClass}>
      <div className={'suppliersCardHeader'}>
        <img src={content.header} alt={content.text} height={content.headerHeight} />
      </div>
      <div className='d-flex flex-column justify-content-between align-items-start pt-s-16 pb-s-16 pe-s-16 ps-s-16'>
        <b className='pb-s-8 font-size-18 ln-21 text-grey-10'>{content.text}</b>
        {content.btn && (
          <>
            <a href={content.link} className='btn btn-sm btn-primary d-none d-md-block'>
              {content.linkText}
            </a>
            <a href={content.link} className='btn btn-sm btn-outline d-sm-block d-md-none'>
              {content.linkText}
            </a>
          </>
        )}
        {content.arrow && (
          <a href='/explore-network/'>
            <div className='d-flex align-items-center'>
              <b className={'font-size-14 ln-17 me-s-8 text-light-green'}> {content.linkText}</b>
              <img src={content.arrow} height={16} width={12} alt={'Arrow Right'} />
            </div>
          </a>
        )}
      </div>
    </div>
  )
}

export default SuppliersCard
