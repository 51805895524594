import * as React from 'react'
import { graphql } from 'gatsby'

// Components
import Benefits from '../components/Benefits'
import Carousel from '../components/Carousel'
import Hero from '../components/Hero'
import Cta from '../components/Cta'
import Layout from '../components/layout'
import MemberTestimonial from '../components/MemberTestimonial'
import Seo from '../components/seo'
import Suppliers from '../components/Suppliers'
import Testimonials from '../components/Testimonials'
import TradeshowsAndHubs from '../components/TradeshowsAndHubs'
import ShowroomsSection from '../components/ShowroomsSection'
import TransactionalModelChanges from '../components/TransactionalModelChanges'
import Video from '../components/Video'

// Content
import { logoSlideContent } from '../content/logoSlide'
import { ctaContent } from '../content/cta'
import { tradeshowsAndHubsContent } from '../content/tradeshowsAndHubs'
import { memberTestimonialContent } from '../content/memberTestimonial'
import { transactionalModelChangesContent } from '../content/transactionalModelChanges'
import { videoVimeoContent } from '../content/vimeoVideo'

const IndexPage = () => {
  return (
    <Layout>
      <Hero />
      <Benefits mode={'lightmain'} />
      <TransactionalModelChanges content={transactionalModelChangesContent} />
      <ShowroomsSection />
      <Video content={videoVimeoContent().video} />
      <MemberTestimonial content={memberTestimonialContent} />
      <TradeshowsAndHubs content={tradeshowsAndHubsContent} />
      <Suppliers />
      <Carousel content={logoSlideContent('big', 'default', true).carousel} />
      <Testimonials />
      <Cta content={ctaContent} />
    </Layout>
  )
}

export const Head = () => <Seo title='FOURSOURCE | Realizing Sourcing Projects Made Easy' />

export default IndexPage

export const query = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
